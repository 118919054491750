import { useEffect, useState } from "react";
import {
  RouterUseQueryStore,
  ContentTypeRender,
  Wrapper,
  UseSearch,
  UseUser,
} from "nystem-components";

const DatabaseFilterByUserAccess = ({ view, model, path }) => {
  const user = UseUser();
  const [, to] = model.map[0];
  const [active, setActive] = RouterUseQueryStore(model.saveId);
  const [value, setValue] = useState(false);
  UseSearch({
    view,
    id: [to, "_id"],
    value: active ? value : undefined,
    exact: model.exact,
  });

  useEffect(() => {
    const [from, , divider] = model.map[0];

    setValue(
      user && user[from] ? user[from].join(divider || "") : "%%%%%%%%%%%%%%%"
    );
  }, [model.map, user, setValue]);

  return (
    <Wrapper
      onClick={() => {
        setActive(!active);
      }}
    >
      {active ? (
        <ContentTypeRender path={path} items={model.itemActive} />
      ) : (
        <ContentTypeRender path={path} items={model.item} />
      )}
    </Wrapper>
  );
};

export default DatabaseFilterByUserAccess;
