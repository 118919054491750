import { SelectInput, Wrapper, ContentTypeRender } from "nystem-components";
import { useEffect, useState } from "react";
import app from "nystem";

const TextSelectOption = ({ view, model, value, setValue, path }) => {
  const [refData, setRefData] = useState({});
  const option = refData[model.optionReferenceField] || [];
  const limit = option.reduce(
    (prev, item) => (item.correct ? prev + 1 : prev),
    0
  );

  useEffect(() => {
    const update = async () => {
      if (!model.optionReference) {
        console.log(view);
        return;
      }
      const { source } = view.model.item.find(
        (item) => item.id === model.optionReference
      );
      if (!source) return;
      const { data } = await app().database[source].get({
        id: view.value[model.optionReference],
      });

      setRefData(data);
    };
    update();
  }, [model, view]);

  useEffect(() => {
    if (!refData || !value) return;
    const correct = option
      .filter((item) => item.correct)
      .map((item) => item._id);
    const val = value instanceof Array ? value : [value];

    view.setValue({
      path: model.scoreField,
      value:
        correct.sort().join(",") === val.sort().join(",")
          ? refData[model.valueField]
          : 0,
    });
  }, [model, option, refData, value, view]);

  if (option.length === 0)
    return <Wrapper>Missing ref question options</Wrapper>;

  if (model.viewOnly)
    return (
      <Wrapper className={model.className}>
        {option.find((item) => item._id === value)?.text || model.fallback}
      </Wrapper>
    );

  return (
    <SelectInput
      model={{ ...model, option, limit }}
      value={value}
      setValue={setValue}
    />
  );
};
export default TextSelectOption;
