import { useEffect, useState } from "react";
import { UseSearch } from "nystem-components";

const DatabaseFilterByBaseSearchResult = ({ view, model }) => {
  const [value, setValue] = useState();
  const [from, to, divider] = model.map[0];
  UseSearch({ view, id: to, value, noListen: true });

  useEffect(() => {
    const updateSearch = ({ data }) => {
      data = data || [];
      setValue(data.map((item) => item[from]).join(divider || ""));
    };
    view.baseView.on("search", -100, updateSearch);
    return () => {
      view.baseView.off("search", updateSearch);
    };
  }, [divider, from, model.map, view]);

  return null;
};

export default DatabaseFilterByBaseSearchResult;
