import { TextInput, Wrapper } from "nystem-components";

const TextColorPicker = ({ model, value, setValue }) =>
  model.viewOnly ? (
    <Wrapper
      className="w-6 h-6 rounded-full ml-2 boder border-white"
      style={{ backgroundColor: value }}
    ></Wrapper>
  ) : (
    <>
      <Wrapper className="flex items-center">
        <TextInput
          model={{ ...model, inputClassName: " " }}
          value={value}
          setValue={(val) => {
            if (val) {
              if (val === "blue") val = "#0000FF";
              if (val === "green") val = "#00FF00";
              if (val === "red") val = "#FF0000";
              if (val === "yellow") val = "#FFFF00";
              if (!val.startsWith("#")) val = `#${val}`;
              if (val.length !== 7) return;
            }
            setValue(val);
          }}
        />
        <Wrapper
          className="w-6 h-6 rounded-full ml-2 boder border-white"
          style={{ backgroundColor: value }}
        ></Wrapper>
      </Wrapper>
      <Wrapper renderAs="p" className="text-sm">
        {model.description}
      </Wrapper>
    </>
  );

export default TextColorPicker;
