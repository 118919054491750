import React from "react";
import { Image } from "nystem-components";
import app from "nystem";

const ViewButtonQrcode = ({ model, view }) => {
  const { className, path } = model;

  return (
    <Image
      className={className}
      src={`/qr/${app().insertVal(path, view)}.svg`}
    />
  );
};
export default ViewButtonQrcode;
