import { Link, SessionRole, Inserter, Wrapper, Icon } from "nystem-components";
import app from "nystem";

const NavbarLink = ({ children, type, icon, ...props }) => {
  const classNames = "px-5 py-2 hover:shadow flex items-center";

  if (type)
    props = { ...props, to: `/admin/${type}/list`, match: `/admin/${type}/*` };

  return (
    <Link
      {...props}
      className={[
        classNames,
        props.className,
        "hover:bg-opacity-30 hover:bg-white hover:shadow",
      ]}
    >
      <Icon icon={icon} className="mr-3 h-4 w-4" />
      <Wrapper className="block flex-grow">{children}</Wrapper>
    </Link>
  );
};

const PartAdminMenu = () => {
  return (
    <SessionRole userrole="config super">
      <NavbarLink
        icon="gear"
        to="/admin"
        className="px-3 text-xl font-semibold sm:text-base"
      >
        {app().t("Admin")}
      </NavbarLink>
      <NavbarLink icon="feather" type="quiz">
        {app().t("Quiz")}
      </NavbarLink>
      <NavbarLink icon="question" type="question">
        {app().t("Question")}
      </NavbarLink>
      <NavbarLink icon="comments" type="answer">
        {app().t("Answer")}
      </NavbarLink>
      <Wrapper className="h-6" />
      <NavbarLink icon="user" type="user">
        {app().t("User")}
      </NavbarLink>
      <NavbarLink icon="user-group" type="group">
        {app().t("Group")}
      </NavbarLink>
      <NavbarLink icon="message" type="message">
        {app().t("Message")}
      </NavbarLink>
      <Wrapper className="h-6" />
      <NavbarLink icon="file" type="content">
        {app().t("Content")}
      </NavbarLink>
    </SessionRole>
  );
};

export default PartAdminMenu;
