import React from "react";
import { Image } from "nystem-components";

const ViewImageView = ({ model }) => {
  const { className, width, height, filename } = model;

  if (width)
    return (
      <Image
        className={className}
        src={`/image/${width}x${height}/${filename}`}
      />
    );
  return <Image className={className} src={`/image/${filename}`} />;
};
export default ViewImageView;
