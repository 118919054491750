import app from "nystem";
import { useEffect } from "react";

const MapStateToStyle = ({ view }) => {
  useEffect(() => {
    const stylePoints = async ({ items }) => {
      const { data: group } = await app().database.group.search({
        filter: { $and: [{ members: app().session.user?._id }] },
      });
      const groupIds = (group || []).map((item) => item._id).join("|");
      let { data: answers } = await app().database.answer.search({
        filter: { $and: [{ group: groupIds }] },
      });

      if (!answers) answers = [];
      const answersById = {};
      answers.forEach((item) => {
        answersById[item.question] = item;
      });
      const styles = view.baseView.getValue("style");

      const parse = (item) => {
        const { _id, value } = item;
        const answer = answersById[_id];
        const style = styles
          .filter(({ state }) => {
            if (!answer) return state === "notyetvisited";
            if (answer.submitted) return state === "finished";
            return state === "open";
          })
          .reduce((prev, style) => {
            if (!prev) return style;
            if (style.value > value) return prev;
            if (style.value < prev.style) return prev;
            return style;
          }, false);

        return { ...item, ...style };
      };
      return { items: items.map(parse) };
    };
    app().on("stylePoints", stylePoints);

    return () => {
      app().off("stylePoints", stylePoints);
    };
  }, [view]);

  return null;
};

export default MapStateToStyle;
