import { useEffect } from "react";
import app from "nystem";

const geoErrors = {
  1: "Request to fetchlocation denied.",
  2: "Location information is unavailable.",
  3: "Get location timed out.",
  4: "Get location unavailable.",
  5: "Need to enable fetch location.",
};

const MapDevicePos = ({ model }) => {
  useEffect(() => {
    let current;
    const setCurrent = (data) => {
      if (data.code)
        current = {
          error: geoErrors[data.code],
          update: true,
          errorCode: data.code,
        };
      else if (data.coords) {
        const {
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
        } = data.coords;

        current = {
          ...current,
          errorCode: undefined,
          error: undefined,
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
          timestamp: data.timestamp,
          update: true,
        };
      } else if (data.gamma) {
        const { alpha, gamma, beta } = data;

        current = {
          ...current,
          alpha,
          gamma,
          beta,
        };
      }
      app().event("mapsDevicePos", current);
    };

    const start = async ({ first }) => {
      if (!navigator.geolocation) setCurrent({ code: 4 });
      else {
        const result = await navigator.permissions.query({
          name: "geolocation",
        });

        if (
          result.state === "granted" ||
          (result.state === "prompt" && !first)
        ) {
          navigator.geolocation.getCurrentPosition(setCurrent, setCurrent);
          navigator.geolocation.watchPosition(setCurrent);
          if (model.deviceorientation)
            window.addEventListener("deviceorientation", setCurrent, true);
        } else {
          setCurrent({ code: result.state === "prompt" ? 5 : 1 });
          window.history.pushState({}, "", model.redirect);
        }
      }
    };
    start({ first: true });
    // window.addEventListener("deviceorientation", setCurrent, true);

    const update = ({ error, timestamp }) => {
      if (error || timestamp) return;
      return { ...current, update: false };
    };

    app().on("mapsDevicePos", 1000, update);
    app().on("askMapsDevicePos", 1000, start);
    return () => {
      app().off("mapsDevicePos", update);
      app().off("askMapsDevicePos", 1000, start);
    };
  }, [model]);

  return null;
};

export default MapDevicePos;

// https://newnow.co/me-myself-and-i/ deviceorientation
// https://chrishewett.com/blog/device-orientation-test-page/
// https://medium.com/hackernoon/building-a-compass-web-app-c79fec31e080
