import { Wrapper, DragAndDropView } from "nystem-components";
import { useRef, useState, useEffect } from "react";
import moment from "my-moment";

const dateTimeFormats = {
  year: "YYYY",
  dateLong: "YYYY-MM-DD",
  time: "H:mm",
  timeLong: "H:mm:ss",
  dateTimeLong: "YYYY-MM-DD HH:mm",
  dateTimeNoYear: "D/M H:mm",
  dateTimeNoYearDay: "ddd D/M H:mm",
  dateNoYearDay: "ddd D/M",
  timeDay: "ddd D/M H:mm",
  dateNoYear: "D/M",
  day: "dddd",
  dayOfMonth: "D",
};

const DateSlider = ({ model, setValue, value, className }) => {
  const { to, from, dateFormat } = model;
  const diff = to - from;
  const [width, setWidth] = useState();
  const [limit, setLimit] = useState();
  const ref = useRef();
  const valref = useRef();

  const format = dateTimeFormats[dateFormat]
    ? dateTimeFormats[dateFormat]
    : dateFormat || dateTimeFormats.dateTimeLong;

  const getLimit = (width, value) => {
    const left = Math.abs(parseInt(((value - from) / diff) * width, 10));
    return { left, right: width - left, top: 0, bottom: 0 };
  };

  useEffect(() => {
    const { width } = ref.current.getBoundingClientRect();
    setWidth(width);
    setLimit(getLimit(width, value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!width)
    return <Wrapper ref={ref} className={[className, model.className]} />;
  valref.current = value;

  return (
    <>
      <Wrapper className="absolute -top-5">
        {moment(from).format(format)}
      </Wrapper>
      <Wrapper className="absolute -top-5 right-0">
        {moment(to).format(format)}
      </Wrapper>
      <Wrapper
        ref={ref}
        className={[className, model.className]}
        style={{ left: `${limit.left}px` }}
      >
        <DragAndDropView
          model={{ ...model, limit }}
          onMove={(e) => {
            setValue(from + parseInt(((limit.left + e.x) / width) * diff, 10));
          }}
          onEnd={() => {
            setLimit(getLimit(width, valref.current));
          }}
        />
      </Wrapper>
    </>
  );
};
export default DateSlider;
