import { useEffect } from "react";
import app from "nystem";

const RouterRedirects = () => {
  useEffect(() => {
    let froms = [];
    let tos = [];

    const update = async () => {
      const { data } = await app().database.redirect.search({});
      if (!data) {
        froms = [];
        tos = [];
        return;
      }

      froms = data.map(({ from }) => (from[0] === "/" ? from : `/${from}`));
      tos = data.map(({ to }) => (to[0] === "/" ? to : `/${to}`));
      locationchange();
    };
    update();
    app().database.redirect.on("change", update);

    const locationchange = () => {
      const index = froms.indexOf(window.location.pathname);
      if (index === -1) return;

      window.history.replaceState({}, "", tos[index]);
    };
    window.addEventListener("locationchange", locationchange);

    return () => {
      window.removeEventListener("locationchange", locationchange);
    };
  }, []);

  return null;
};

export default RouterRedirects;
