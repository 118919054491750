import {
  Link,
  Wrapper,
  SessionLogout,
  ConnectionView,
  Icon,
  PartAdminMenu,
  Inserter,
  SessionRole,
} from "nystem-components";
import { useState, useEffect } from "react";
import app from "nystem";

const PartMenu = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const close = () => {
      if (open === true) setOpen(false);
    };
    setTimeout(() => {
      window.addEventListener("click", close);
    }, 0);
    return () => {
      window.removeEventListener("click", close);
    };
  }, [open]);

  return (
    <Wrapper
      renderAs="header"
      className="bg-menu py-1 fixed top-0 z-20 w-full pl-3 pr-2 md:mx-0 print-hidden"
    >
      <Wrapper
        renderAs="nav"
        className="flex max-w-xl m-auto text-sm justify-center items-center"
      >
        <Link to="/" exact="true">
          <ConnectionView>
            <Wrapper className="logo-font text-3xl md:text-4xl">
              Tipspromenad
            </Wrapper>
          </ConnectionView>
          <ConnectionView offline="true">
            <Wrapper className="logo-font text-3xl md:text-4xl text-gray-400 text-shadow">
              Tipspromenad
            </Wrapper>
          </ConnectionView>
        </Link>
        <Wrapper className="flex flex-grow h-14"></Wrapper>
        <SessionRole userrole="logged-in">
          <Link
            className="shadow rounded bg-opacity-30 bg-white text-center cursor-pointer p-1 ml-2 hover:bg-opacity-30 hover:bg-white hover:shadow px-2 block rounded-md"
            to={`/messages`}
          >
            <Icon icon="regular-message" className="w-8 h-8" />
          </Link>
          <Inserter match="*" source="/quiz/indicator" />
        </SessionRole>
        <SessionRole userrole="config super">
          <Icon
            icon="bars"
            className="shadow mx-1 rounded bg-opacity-30 bg-white p-1 w-10 h-10 cursor-pointer"
            onClick={() => setOpen(!open)}
          />
          <Wrapper className="relative w-1">
            {open ? (
              <Wrapper className="absolute w-32 mt-6 right-0 text-sm shadow mx-1 rounded bg-opacity-60 bg-white p-1">
                <PartAdminMenu />
                <SessionLogout
                  type="button"
                  className="shadow ml-4 mt-3 rounded bg-opacity-30 bg-white p-1 text-center"
                >
                  {app().t("Log out")}
                </SessionLogout>
              </Wrapper>
            ) : null}
          </Wrapper>
        </SessionRole>
        <SessionRole userrole="user">
          <SessionLogout
            type="button"
            className="shadow rounded bg-opacity-30 bg-white text-center cursor-pointer p-1 ml-1"
          >
            <Icon icon="right-from-bracket" className="w-8 h-8" />
          </SessionLogout>
        </SessionRole>
      </Wrapper>
    </Wrapper>
  );
};
export default PartMenu;
