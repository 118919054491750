import { useEffect, useRef } from "react";
import app from "nystem";

const ViewButtonEventOnLoad = ({ model, view, path }) => {
  const sendRef = useRef();

  const pick = (fields) =>
    fields?.reduce((prev, [id, to]) => {
      const val = app().insertVal(id, view);
      return val ? { ...prev, [to]: val } : prev;
    }, {});

  const emitterByType = {
    connection: {
      event: (type, data) => app().connection.emit({ type, ...data }),
    },
    view: view,
    baseView: view.baseView,
    baseViewBaseView: view.baseView?.baseView,
    baseViewBaseViewBaseView: view.baseView?.baseView?.baseView,
    app: app(),
  };

  sendRef.current = emitterByType[model.eventType || "connection"];

  useEffect(() => {
    sendRef.current.event(model.event, {
      event: model.subEvent,
      contentType: view.contentType,
      ...pick(model.fields),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
export default ViewButtonEventOnLoad;
