import {
  SessionRole,
  Inserter,
  Wrapper,
  PartAdminMenu,
} from "nystem-components";

const PartAdmin = () => {
  return (
    <SessionRole userrole="config super">
      <Inserter
        match="/admin*"
        className="admin m-auto max-w-6xl p-1 sm:flex pt-16"
      >
        <Wrapper className="hidden md:block shadow mx-1 rounded bg-opacity-30 bg-white p-1">
          <PartAdminMenu />
        </Wrapper>
        <Wrapper className="flex-grow px-3 pt-2 pb-32 max-w-3xl">
          <Inserter match="/admin" source="/user/list" />
          <Inserter match="/admin/content*" source="/content*" />
          <Inserter match="/admin/user*" source="/user*" />
          <Inserter match="/admin/language*" source="/language*" />
          <Inserter match="/admin/question*" source="/question*" />
          <Inserter match="/admin/quiz*" source="/quiz*" />
          <Inserter match="/admin/group*" source="/group*" />
          <Inserter match="/admin/translation*" source="/translation*" />
          <Inserter match="/admin/message*" source="/message*" />
          <Inserter match="/admin/answer*" source="/answer*" />
        </Wrapper>
      </Inserter>
    </SessionRole>
  );
};

export default PartAdmin;
