import React, { useEffect, useRef, useState } from "react";
import { InputWrapper, Wrapper } from "nystem-components";
import app from "nystem";
import "./input.css";

const addScript = (src) =>
  new Promise((resolve) => {
    const script = [...document.head.children].find(
      (child) => child.getAttribute("src") === src
    );

    if (script) {
      if (script.getAttribute("data-loaded")) resolve();
      else script.addEventListener("load", resolve);

      return;
    }

    const scriptEl = document.createElement("script");

    scriptEl.setAttribute("src", src);
    scriptEl.addEventListener("load", () => {
      scriptEl.setAttribute("data-loaded", true);
      resolve();
    });
    document.head.appendChild(scriptEl);
  });

const defautSettings = [
  { id: "paste", plugin: "paste", buttons: ["undo", "redo"] },
  {
    id: "style",
    buttons: ["bold", "italic"],
    pasteWordValidElements: ["strong", "b", "i", "em"],
  },
  {
    id: "align",
    buttons: ["alignleft", "aligncenter", "alignright", "alignjustify"],
  },
  { id: "indent", buttons: ["outdent", "indent"] },
  {
    id: "list",
    plugin: "lists",
    buttons: ["bullist", "numlist"],
    pasteWordValidElements: ["li", "ul", "ol"],
  },
  {
    id: "header",
    buttons: ["h1", "h2", "h3", "h4", "h5", "h6"],
    pasteWordValidElements: ["h1", "h2", "h3", "h4", "h5", "h6"],
  },
  { id: "hr", plugin: "hr", buttons: ["hr"] },
  {
    id: "nystem",
    buttons: [],
    extendedValidElements: ["nystem-component[value|contenttype|format]"],
    customElements: ["nystem-component,~nystem-component"],
    validChildren: [
      "+p[nystem-component]",
      "+strong[nystem-component]",
      "+em[nystem-component]",
      "+i[nystem-component]",
      "+b[nystem-component]",
    ],
    pasteWordValidElements: ["p"],
  },
];

const traverseTree = (element, doFunc) => {
  doFunc(element);
  [...element.childNodes].forEach((element) => traverseTree(element, doFunc));
};

const removeEmptyHeaders = (text) =>
  text.replace(/<H([0-5])([^>]*)>(.*?)<\/H\1>/gim, (all, tag, attr, html) => {
    if (
      !html
        .replace(/<(b|i|em|strong)\/?>/gim, "")
        .replace(/<([A-Z][A-Z-0-9]*)[^>]*>(.*?)<\/\1>/gim, "")
        .replace(/<([A-Z][A-Z-0-9]*)[^>]*>/gim, "")
        .replace(/&nbsp;/gim, "").length
    )
      return `<p${attr}>${html}</p>`;
    return `<H${tag}${attr}>${html}</H${tag}>`;
  });

const TinymceInput = ({ value = "", setValue, model, view, path }) => {
  const text = useRef();
  const bottom = useRef();
  const setVal = useRef();
  const val = useRef(value);

  setVal.current = setValue;
  const { inputClassName = [] } = model;
  const [className, setClassName] = useState(inputClassName);
  const [id] = useState(app().uuid());

  useEffect(() => {
    const getView = (query) => {
      if (query.viewId !== id) return;
      return { ...query, baseView: view };
    };
    app().on(`tinymceComponent`, 100, getView);

    return () => {
      app().event(`reactPortalViewClose`, { id });
      app().off(`tinymceComponent`, getView);
    };
  }, [id, view]);

  useEffect(() => {
    const tinymceInputClass = ({ className }) => {
      setClassName([...inputClassName, ...className]);
    };
    view.on(`tinymceInputClass${path}`, -10, tinymceInputClass);
    return () => {
      view.off(`tinymceInputClass${path}`, tinymceInputClass);
    };
  }, [inputClassName, path, view]);

  useEffect(() => {
    const id = path + model.id;

    text.current.style.visibility = "hidden";
    let editorRef = false;
    let saveFunction = false;
    if (!model.minHeight) model.minHeight = 50;

    addScript("/tinymce/tinymce.min.js").then(async () => {
      if (!window.tinymce) return;

      const { toolbarItems = [] } = model;

      const { settings, menubar } = await view.event(`tinymceInit${id}`, {
        settings: defautSettings.map((item) => ({
          ...item,
          buttons: item.buttons.filter((type) => toolbarItems.includes(type)),
        })),
        menubar: false,
      });

      const setHeight = (second) => {
        if (!text.current) return;

        let minHeight =
          bottom.current.getBoundingClientRect().top -
          text.current.getBoundingClientRect().top;

        if (minHeight < model.minHeight) ({ minHeight } = model);

        text.current.style.minHeight = "0px";
        text.current.style.minHeight = `${minHeight}px`;
        if (!second) setTimeout(() => setHeight(true), 500);
      };

      window.tinymce.init({
        target: text.current,
        branding: false,
        elementpath: false,
        statusbar: false,
        inline: true,
        custom_elements: settings
          .map((item) => item.customElements || [])
          .flat()
          .join(","),
        extended_valid_elements: settings
          .map((item) => item.extendedValidElements || [])
          .flat()
          .join(","),
        toolbar: settings
          .map((item) => [...item.buttons, "|"])
          .flat()
          .join(" "),
        plugins: settings.map((item) => item.plugin).join(" "),
        paste_word_valid_elements: settings
          .map((item) => item.pasteWordValidElements || [])
          .flat()
          .join(","),
        valid_children: [
          "p",
          ...settings.map((item) => item.validChildren || []).flat(),
        ].join(","),
        menubar,

        init_instance_callback: function (editor) {
          editor.addShortcut("ctrl+s", "Custom Ctrl+S", "custom_ctrl_s");
          editor.addCommand("custom_ctrl_s", () => {
            view.event("submit");
          });

          traverseTree(text.current, (element) => {
            if (element.nodeName !== "#text") return;

            const { data } = element;

            if (data.endsWith("åååå"))
              element.data = `${data.substring(0, data.length - 4)} `;

            if (data.startsWith("åååå")) element.data = ` ${data.substring(4)}`;
          });

          if (text.current.innerHTML.includes("åååå"))
            traverseTree(text.current, (element) => {
              if (element.nodeName !== "#text") return;

              if (element.data.endsWith("åååå"))
                element.data = `${element.data.substring(
                  0,
                  element.data.length - 4
                )} `;
            });

          setHeight();

          text.current.style.visibility = "visible";
          editorRef = editor;
        },

        setup: async (editor) => {
          saveFunction = () => {
            if (!text.current) return;

            setTimeout(() => {
              setVal.current(removeEmptyHeaders(text.current.innerHTML));
            }, 200);
            setHeight();
          };
          editor.on("Change", saveFunction);
          editor.on("click keydown", saveFunction);

          view.on(`tinyMceEditorSave${id}`, saveFunction);
          view.on(`tinyMceFocus${id}`, () => editor.focus());
          view.on("change", ({ value }) => {
            if (!value[model.id]) text.current.innerHTML = "";
          });
          // view.on(`tinyMceEditorBlur${id}`, () => editor.blur());
          await view.event(`tinymceSetup${id}`, { editor });
        },
      });
    });

    return () => {
      if (saveFunction) view.off(`tinyMceEditorSave${id}`, saveFunction);
      view.event(`tinyMceEditorBlur${id}`);

      app().event(`reactPortalViewClose`, { id });
      if (editorRef) editorRef.remove();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  val.current = val.current
    .replace(/ <nystem-component /g, "åååå<nystem-component ")
    .replace(/<\/nystem-component> /g, "</nystem-component>åååå");

  return (
    <InputWrapper model={model} error={false}>
      <Wrapper
        className="h-10 border w-full relative p-2 text-gray-500 "
        style={{ top: "-1px" }}
        onClick={() => view.event(`tinyMceFocus${path + model.id}`)}
      >
        Wysiwyg editor
      </Wrapper>
      <Wrapper
        id={id}
        className={[className, "tinymceinput", "tinymceview"]}
        ref={text}
        dangerouslySetInnerHTML={{ __html: val.current }}
      />
      <Wrapper ref={bottom} className="clear-both" />
    </InputWrapper>
  );
};

export default TinymceInput;
