import {
  Inserter,
  SessionRole,
  DragAndDropContext,
  TinymcePortal,
  RouterPageNotFound,
  ErrorBoundry,
  PartAdmin,
  PartMenu,
} from "nystem-components";

import "./index.css";

const Index = () => (
  <ErrorBoundry fallback={<Inserter match="*" source="/content/page/error" />}>
    <DragAndDropContext>
      <PartMenu />
      <PartAdmin />
      <TinymcePortal />
      <Inserter match="/content/page/*" />
      <SessionRole userrole="logged-out">
        <Inserter match="*" source="/user/login" />
      </SessionRole>
      <SessionRole userrole="logged-in">
        <Inserter match="/" className="mt-16 mx-auto  max-w-xl">
          <Inserter match="*" source="/group/myquizes" />
          <RouterPageNotFound limit={2}>
            <h1>No quiz found</h1>
          </RouterPageNotFound>
        </Inserter>
        <Inserter className="mt-16" match="/quiz/*" source="/quiz/*" />
        <Inserter
          className="mt-16 mx-auto max-w-xl"
          match="/question/*"
          source="/question/*"
        />
        <Inserter
          className="mt-16 mx-auto  max-w-xl"
          match="/messages"
          source="/message/user"
        />
        <Inserter className="mt-16" match="/message/*" source="/message/*" />
        <Inserter
          className="mt-16 mx-auto max-w-xl"
          match="/location"
          source="/quiz/indicatormsg"
        />
      </SessionRole>
      <RouterPageNotFound limit={5}>
        <Inserter match="*" source="/content/page/404" />
      </RouterPageNotFound>
    </DragAndDropContext>
  </ErrorBoundry>
);

export default Index;
